@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 400;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Regular.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 400;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 250;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Thin.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 250;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Thin Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 275;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro ExtraLight.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 275;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro ExtraLight Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 300;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Light.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 300;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Light Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 500;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Medium.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 500;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Medium Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 700;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 700;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Bold Italic.woff') format('woff');
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 800;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro ExtraBold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 800;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro ExtraBold Italic.woff') format('woff');
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 900;
  src:
    local('TT Norms Pro'),
    url('https://fonts.cdnfonts.com/s/39510/TT Norms Pro Black.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 900;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro Black Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: tt norms pro;
  font-style: normal;
  font-weight: 1000;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro ExtraBlack.woff') format('woff');
}
@font-face {
  font-family: tt norms pro;
  font-style: italic;
  font-weight: 1000;
  src:
    local('TT Norms Pro'),
    url('/assets/fonts/TT Norms Pro ExtraBlack Italic.woff') format('woff');
  font-display: swap;
}
