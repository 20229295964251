/* stylelint-disable at-rule-no-unknown */

/* You can add global styles to this file, and also import other style files */
@import url('./app/core/components/ui-kit/theme.scss');
@import url('@angular/cdk/overlay-prebuilt.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-bg-primary: 17 124 250;
    --color-bg-card: 31 45 64;
    --color-bg-card-hover: 36 50 69;
    --color-bg-card-active: 26 40 59;
    --color-text-primary: 252 252 253;
    --color-text-subtle: 209 209 209;
    --color-text-danger: 255 100 89;
    --color-text-link: 50 160 250;
    --color-text-link-hover: 70 180 250;
    --color-text-link-active: 40 140 220;
    --color-probe-succeeded-background-color: 47 148 74;
    --color-probe-failed-background-color: 190 60 60;
    --color-probe-nodata-background-color: 63 75 90;
    --color-chip-bg: 97 175 230;
    --color-chip-text: 97 175 230;

    font-variant-ligatures: none;
  }

  a {
    @apply text-link hover:text-link-hover active:text-link-active;
  }
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-height: 100vh;
}

html {
  --v-input-background: rgb(0 0 0 / 30%);
}

.cdk-overlay-container {
  z-index: 5000 !important;
}

.cdk-overlay-pane {
  z-index: 5000 !important;
}
