@import url('./core/card.scss');
@import url('./core/backgrounds.scss');

:root {
  --v-primary-color: #117cfa;
  --v-primary-light-color: #4c9cfb;
  --v-primary-dark-color: #044ba0;
  --v-primary-text-color: #fcfcfd;
  --v-primary-text-color-accent: #d1d1d1;
  --v-secondary-color: #f2841f;
  --v-secondary-dark-color: #c1620b;
  --v-border-radius: 0.7rem;
  --v-danger-color: #f14f44;
  --v-danger-background-color: #f4443720;
  --v-warning-color: #f2841f;
  --v-warning-background-color: #f2841f20;
  --v-success-color: #6ce410;
  --v-success-background-color: #6ce41020;
  --v-info-color: #4c9cfb;
  --v-info-background-color: #117cfa20;
  --v-neutral-color: #d1d1d1;
  --v-neutral-background-color: #d1d1d120;
  --v-card-background: #282a2d;
  --v-card-background-light: #3b3f43;
  --v-card-border-radius: 2rem;
  --v-spacing-xxs: 0.25rem;
  --v-spacing-xs: 0.5rem;
  --v-spacing-s: 0.8rem;
  --v-spacing-m: 1rem;
  --v-spacing-l: 1.5rem;
  --v-spacing-xl: 2rem;

  /* input */
  --v-input-background: rgb(0 0 0 / 30%);
  --v-input-color: var(--v-primary-text-color);
  --v-input-outline-color: #afd3fd;
  --v-input-placeholder-color: #565b62;
  --v-input-focus-outline-color: var(--v-input-outline-color);

  /* dropdown */
  --v-dropdown-background-color: rgb(0 0 0 / 30%);
  --v-dropdown-placeholder-color: var(--v-input-placeholder-color);
  --v-dropdown-focus-outline-color: var(--v-input-outline-color);
  --v-dropdown-panel-background-color: #282a2d;
}

* {
  box-sizing: border-box;
  font-family: 'TT Norms Pro', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.v-text-primary {
  color: var(--v-primary-text-color);
}

.v-text-subtle {
  color: var(--v-primary-text-color-accent);
}

.v-text-danger {
  color: var(--v-danger-color);
}

.v-text-success {
  color: var(--v-success-color);
}

.v-card-bg {
  background-color: rgb(31 45 64);
}

.v-scrollbar {
  scrollbar-color: rgba(0 0 0 / 30%) transparent;
  scrollbar-width: auto;
}
